import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
// Pages
import Home from './Home/Home';
import Nav from './Nav/Nav';
import Footer from './Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
