import './Footer.scss';
import React from 'react';

function Footer() {
    return (
        <div className='footer-wrapper'>
            <p><a className='footer-link' href="mailto:matt@camdenseal.com">matt@camdenseal.com</a></p>
            <p>(701) 306-9960</p>
            <p>camdensmartseal.com</p>
        </div>
    )
}

export default Footer;