import { Link } from "react-router-dom";
import './Nav.scss';
import CamdenLogo from "../assets/camden-logo-01.png"
import React, { useEffect } from 'react';
import { PopupButton } from "react-calendly";



function Nav() {

    return (
        <>
            <div className="nav-container">
                <div className="link-group left">
                    <Link to="/home"><img className="camden-logo" src={CamdenLogo} /></Link>
                    <a className="navlink" href="#how-it-works-wrapper">How It Works</a>
                    <a className="navlink" href="#benefit-wrapper">Benefits</a>
                    <a className="navlink" href="#contact-wrapper">Contact</a>
                </div>

                <div className="link-group right">
                    <p>(701) 306-9960</p>
                    <PopupButton
                        className="schedule-btn-nav"
                        url="https://calendly.com/matt-ues/camden-smart-seal-consultation"
                        rootElement={document.getElementById("root")}
                        text="Schedule Now"
                    />
                    <p>matt@camdenseal.com</p>
                </div>
            </div >
        </>
    )
}


export default Nav;