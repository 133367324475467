import './Home.scss';
import React, { useState } from 'react';
import { PopupButton } from "react-calendly";

// Images
import SealingStation from '../assets/seal-station.jpg'
import ProfessionalMatt from '../assets/professional-matt.png'

// Icons
import ClockIcon from '../assets/clock-01.svg'
import CloudIcon from '../assets/cloud-01.svg'
import GearIcon from '../assets/gear-01.svg'
import HomeIcon from '../assets/home-01.svg'
import LeafIcon from '../assets/leaf-01.svg'
import PiggyIcon from '../assets/piggy-01.svg'

function Home() {

    const [activeTechnique, setActiveTechnique] = useState('');

    const selectTechnique = (technique) => {
        setActiveTechnique(technique);
    }

    return (
        <div className='home-wrapper'>
            <div className="banner-wrapper">
                <div className='header-tagline'>
                    <h1>Guaranteed HVAC Duct and Home Envelope Air Sealing Technology</h1>
                    <h4>Improving the lives of people world-wide by delivering comfort, healthy air, and substantial energy savings.</h4>
                </div>
                <div>
                    <img className='header-image' src={SealingStation} />
                </div>
            </div>
            <div className='how-it-works-wrapper' id='how-it-works-wrapper'>
                <h3 className='hiw-title'>Available Techniques</h3>
                <p className='hiw-subtitle'>Click a technique to learn more</p>
                <div className='techniques-wrapper'>
                    <button className={'technique-btn' + (activeTechnique === 'Duct' ? ' selected' : ' ')} onClick={() => selectTechnique('Duct')}>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/06/Ductwork-101_Blog-Feature-Image.png' /><br />
                        <p className='technique-title'>HVAC Duct</p>
                    </button>
                    <button className={'technique-btn' + (activeTechnique === 'Envelope' ? ' selected' : ' ')} onClick={() => selectTechnique('Envelope')}>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/05/Duct-Step-2.png#458' />
                        <p className='technique-title'>Building Envelope</p>
                    </button>
                </div>
                {/* Duct */}
                <div className={'hiw-step-wrapper' + (activeTechnique === 'Duct' ? ' visible' : ' hidden')}>
                    <div className='hiw-step'>
                        <div className='step-info'>
                            <h3>Step 1</h3>
                            <h6>WE BEGIN THE AEROSEAL PROCESS</h6>
                            <p>
                                First, we block all your vents and registers to pressurize your system.
                                <br /><br />
                                The internal components of your furnace are also blocked off to protect them from the sealant being blown through the ductwork.
                                <br /><br />
                                This system pressurization allows our technology to find all the cracks and holes where air is leaking.
                                <br /><br />
                                Next, we hook up the Aeroseal machine to measure your pre-seal air duct leakage, giving you a before and after snapshot. More on that later.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/06/Ductwork_vs_Duct_Work_Feature_Image.jpg' />
                    </div>
                    <div className='hiw-step reverse'>
                        <div className='step-info'>
                            <h3>Step 2</h3>
                            <h6>WE SEAL YOUR LEAKS WITH PRECISION</h6>
                            <p>We are then ready to begin distributing our sealant formula throughout your air vent and duct system.
                                <br /><br />
                                While sealing, we monitor your system’s performance and show you all the improvements, as they happen.
                                <br /><br />
                                Our technology is so precise that we only use the amount of sealant necessary with virtually no waste and very little cleanup.
                                <br /><br />
                                This process can vary between 60 minutes to a couple hours, depending on your initial system leakage amounts.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/06/Duct_Leakage_101_Feature_Image.jpg' />
                    </div>
                    <div className='hiw-step'>
                        <div className='step-info'>
                            <h3>Step 3</h3>
                            <h6>YOU SEE & FEEL THE DIFFERENCE</h6>
                            <p>
                                When we’re finished, a certificate of completion, including both before & after system leakage measurements, is printed and presented to you.
                                <br /><br />
                                Breathe easy knowing your investment is backed by a 10 year warranty, and last many years longer.
                                <br /><br />
                                More importantly, you’ll feel the difference in your home right away.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/06/NTH-Gallery-Images.jpg' />
                    </div>
                    <div className='hiw-video'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/yjTJROXSh0I?si=Q6GNbfAg0iXVHkn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                {/* Envelope */}
                <div className={'hiw-step-wrapper' + (activeTechnique === 'Envelope' ? ' visible' : ' hidden')}>
                    <div className='hiw-step'>
                        <div className='step-info'>
                            <h3>Step 1</h3>
                            <h6>Prep & Step</h6>
                            <p>
                                Prep the area by covering any openings that won’t be sealed. This includes taping or covering any designed openings or
                                finished horizontal surfaces within the space. Emitters are then set up throughout the area to be air sealed.
                                <br /><br />
                                The AeroBarrier Connect system is then set up. It includes a blower door, nozzle stations and hoses, a fan with lay-flat tubing, and the master control unit.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/05/Envelope-Step-1.png' />
                    </div>
                    <div className='hiw-step reverse'>
                        <div className='step-info'>
                            <h3>Step 2</h3>
                            <h6>Pressurize & Apply</h6>
                            <p>
                                Using a blower door, the space is pressurized. After that, a computer controls the entire process including the temperature, pressure, humidity, and distribution of sealant within the space.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/05/Envelope-Step-2.png' />
                    </div>
                    <div className='hiw-step'>
                        <div className='step-info'>
                            <h3>Step 3</h3>
                            <h6>Seal & Monitor</h6>
                            <p>
                                With the AeroBarrier Connect system, you see results in real-time. You have complete control over your desired level of airtightness and can achieve any level required. At the end of the process, a final blower door test is run to verify the air sealing results. AeroBarrier then provides a Certificate of Completion that shows pre and post leakage.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/05/Envelope-Step-3.png' />
                    </div>
                    <div className='hiw-step reverse'>
                        <div className='step-info'>
                            <h3>Step 4</h3>
                            <h6>Clean Up</h6>
                            <p>
                                After the air sealing is complete, work can resume in the space within 30 minutes. At that time, clean up of all equipment and removal of all tape and coverings can occur.
                            </p>
                        </div>
                        <img src='https://aeroseal.com/wp-content/uploads/2024/05/Envelope-Step-4.png' />
                    </div>
                    <div className='hiw-video'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/HHEOLfmypyc?si=d6wz4GtT58LNB3Iq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className='benefits-wrapper' id='benefit-wrapper'>
                <h3 className='benefit-title'>Unlock the Benefits</h3>
                <div className='benefits-grid'>
                    <div className='benefit'>
                        <img className='benefit-icon' src={HomeIcon} />
                        <h3 className='perk-title'>Reliably Comfy</h3>
                        <p>Enjoy every room in your house. No more hard-to-heat spaces. Your house will even feel better.</p>
                    </div>
                    <div className='benefit'>
                        <img className='benefit-icon' src={CloudIcon} />
                        <h3 className='perk-title'>Healthier Air</h3>
                        <p>Say goodbye to dirty air, allergens, along with most unwanted noise and odor.</p>
                    </div>
                    <div className='benefit'>
                        <img className='benefit-icon' src={PiggyIcon} />
                        <h3 className='perk-title'>Real Savings</h3>
                        <p>With most leaks eliminated from ductwork and the building envelope, energy savings will stack up!</p>
                    </div>
                    <div className='benefit'>
                        <img className='benefit-icon' src={GearIcon} />
                        <h3 className='perk-title'>Better Performance</h3>
                        <p>You’ll get the most out of your system – more air from your vents and longer system life.</p>
                    </div>
                    <div className='benefit'>
                        <img className='benefit-icon' src={LeafIcon} />
                        <h3 className='perk-title'>Eco-Friendly Home</h3>
                        <p>Be proud of a more efficient home. You’ll save money <i>and</i> the planet at the same time.</p>
                    </div>
                    <div className='benefit'>
                        <img className='benefit-icon' src={ClockIcon} />
                        <h3 className='perk-title'>Fast & Easy For New Construction</h3>
                        <p>We work with all Builders to streamline the process and adhere to their construction schedule. Easy!</p>
                    </div>
                </div>
            </div>
            <div className='schedule-wrapper' id='contact-wrapper'>
                <div className='schedule-container owner'>
                    <img className='owner-picture' src={ProfessionalMatt} />
                </div>
                <div className='schedule-container info'>
                    <h3 className='schedule-title'>Ready to Take the Next Step? Let's Talk.</h3>
                    <PopupButton
                        className='schedule-now-btn'
                        url="https://calendly.com/matt-ues/camden-smart-seal-consultation"
                        rootElement={document.getElementById("root")}
                        text="Schedule Now"
                    />
                </div>
            </div>
        </div>
    )
}

export default Home;